<template>
  <div class="px-1">
    <div class1="tab-pane fade show active" v-if="!(start || isAnalys)">
      <Topic v-if="type == 'topic'" :prepare="prepare" :loadedList="loadedList" :g="groups" :q="q_list" :lang="lang" @setBilet="onStartGroup($event)" />

      <div v-else-if="type == 'subGroups'" class="container" style="text-align: left; margin: 15px 0">
        <div class="row justify-content-center">
          <div v-for="(el, i) in subGroups" :key="i" class="d-flex1 col-sm-6 col-lg-6 col-xl-6">
            <div class="" style="text-align: center; margin: 2px auto">
              <button v-if="el.type == 'back'" class="btn btn-secondary btn-block" style="min-height: 62px; width1: 280px; margin: 2px" @click="type = 'topic'">
                ...Назад
              </button>
              <button v-else class="btn btn-secondary btn-block" style="min-height: 62px; width1: 280px; margin: 2px" @click="onStartGroup(el.id, el)">
                {{ subG[el.name].name }} ({{ subG[el.name].count }})
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="type == 'bilet'" style="text-align: center; margin: 15px 0">
        <h4>Категория AB</h4>
        <span v-for="(el, key) in listBilet" :key="key">
          <button
            v-if="el != 0"
            :disabled="prepare || !loadedList"
            class="btn btn-secondary"
            style="width: 100px; margin: 2px; padding: 3px 6px"
            @click="
              typeP = [1];
              onStart(el);
            "
          >
            <span v-if="selected == el && prepare"> Подготовка </span>
            <span v-else> {{ $t("Ticket") }} {{ el }} </span>
          </button>
        </span>

        <div class="mt-4" v-if="lang !== 'kg'">
          <h4>Категория CD</h4>
          <span v-for="(el, key) in listBiletCD" :key="`cd-${key}`">
            <button
              v-if="el != 0"
              :disabled="prepare || !loadedList"
              class="btn btn-secondary"
              style="width: 100px; margin: 2px; padding: 3px 6px"
              @click="onClickCD(el)"
            >
              <span v-if="selected == el && prepare"> Подготовка </span>
              <span v-else> {{ $t("Ticket") }} {{ el }} </span>
            </button>
          </span>
        </div>
        <div class="mt-4" v-else>
          <h4>Категория CD</h4>
          Скоро будут доступны
        </div>
        <div v-if="($root.authData && [36, 40].includes($root.authData.id)) || true" class="mt-4">
          <h4>Вопросы от JOLDO.kg</h4>
          <span v-for="(el, key) in listBiletExt" :key="key">
            <button
              v-if="el != 0"
              :disabled="prepare || !loadedList"
              class="btn btn-secondary"
              style="width: 100px; margin: 2px; padding: 3px 6px"
              @click="
                typeP = [2];
                onStart(el);
              "
            >
              <span v-if="selected == el && prepare"> Подготовка </span>
              <span v-else> {{ $t("Ticket") }} {{ el }} </span>
            </button>
          </span>
        </div>
      </div>
      <div v-else-if="type == 'favorites'" style="text-align: center; margin: 15px 0">
        <div style="text-align: center; margin: 10px 0">
          <h4 style="text-align: center; margin: 25px" v-html="$t(`${type}_name_text`)"></h4>
          <div v-if="!(auth && auth.id)">
            <p>В этот раздел можно добавлять вопросы, которые Вы хотите учить отдельно. Для этого Вам нужно выбрать вопрос в тестах, нажав на звездочку.</p>
            <p>Эта функция доступна только для тех, кто авторизовался на сайте.</p>
            <p>Для авторизации нажмите на кнопку «Войти» в верхней части сайта.</p>
          </div>
          <div v-else-if="favorites.length">
            <p>Количество вопросов: {{ favorites.length }}</p>
            <button class="btn btn-primary" :class="{ disabled: prepare || !loadedList }" @click="onStartFavorites()">
              <span v-if="!prepare">Начать</span>
              <span v-if="prepare && !loadedB">Подготовка....{{ loadProgress }}%</span>
            </button>
          </div>
          <div v-else>
            <p>В этот раздел можно добавлять вопросы, которые Вы хотите учить отдельно. Для этого Вам нужно выбрать вопрос в тестах, нажав на звездочку.</p>
            <p>Вы еще не добавили ни один вопрос в "избранное".</p>
          </div>
        </div>
      </div>
      <div v-else-if="type == 'marathon'" style="text-align: center; margin: 15px 0">
        <div style="text-align: center; margin: 10px 0">
          <div>
            <h4 style="text-align: center; margin: 25px" v-html="$t(`${type}_name_text`)"></h4>
            <div>
              <div style="text-align: center; margin: 10px 0">
                <button
                  class="btn btn-primary"
                  :class="{ disabled: prepare || !loadedList }"
                  @click="
                    typeP.push(1);
                    onStartFavorites();
                  "
                >
                  <span v-if="!prepare"> Категория AB</span>
                  <span v-if="prepare && !loadedB">Подготовка... {{ loadProgress }}%</span>
                </button>
              </div>
              <div style="text-align: center; margin: 10px 0">
                <button
                  v-if="lang !== 'kg' && false"
                  class="btn btn-primary"
                  :class="{ disabled: prepare || !loadedList }"
                  @click="
                    typeP.push(3);
                    onStartFavorites();
                  "
                >
                  <span v-if="!prepare"> Категория CD</span>
                  <span v-if="prepare && !loadedB">Подготовка... {{ loadProgress }}%</span>
                </button>
              </div>

              <div style="margin-top: 10px">{{ $t("Включить дополнительные вопросы") }}</div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" :value="2" id="typeJOLDO" v-model="typeP" />
                <label class="form-check-label" for="typeJOLDO"> {{ $t(`Вопросы JOLDO`) }} </label>
              </div>
            </div>
            <button v-if="false" class="btn btn-primary mt-2" :class="{ disabled: prepare || !loadedList }" :disabled="typeP.length == 0" @click="onStartFavorites()">
              <span v-if="!prepare">Начать</span>
              <span v-if="prepare && !loadedB">Подготовка... {{ loadProgress }}%</span>
            </button>
          </div>
        </div>
      </div>
      <div v-else-if="type == 'myErrors'" style="text-align: center; margin: 15px 0">
        <div style="text-align: center; margin: 10px 0">
          <h4 style="text-align: center; margin: 25px" v-html="$t(`${type}_name_text`)"></h4>
          <div v-if="!(auth && auth.id)">
            <p>В этом разделе вы можете решать вопросы, в которых вы допустили ошибку.</p>
            <p>Ответив правильно три раза подряд, вопрос будет считаться решеным и удалится из этого раздела. </p>
            <p>Эта функция доступна только для тех, кто авторизовался на сайте.</p>
            <p>Для авторизации нажмите на кнопку «Войти» в верхней части сайта.</p>
          </div>
          <div v-else-if="myErrors">
            <p>{{ $t("Количество вопросов") }}: {{ myErrors }}</p>
            <p>Ответив правильно три раза подряд, вопрос будет считаться решеным и удалится из этого раздела. </p>
            <div style="text-align: center; margin: 10px 0">
              <button class="btn btn-primary" :class="{ disabled: prepare }" @click="onStartFavorites()">
                <span v-if="!prepare">Начать</span>
                <span v-if="prepare">Подготовка....{{ loadProgress }}%</span>
              </button>
            </div>
            <div style="text-align: center; margin: 10px 0">
              <button class="btn btn-primary" :class="{ disabled: prepare }" @click="onClearError()">
                <span v-if="!prepare">{{ $t("Очистить") }}</span>
              </button>
            </div>
          </div>
          <div v-else>
            <p>Список пуст</p>
            <p>В этом разделе вы можете решать вопросы, в которых вы допустили ошибку.</p>
            <p>Ответив правильно три раза подряд, вопрос будет считаться решеным и удалится из этого раздела. </p>
          </div>
        </div>
      </div>
      <div v-else>
        <h4 style="text-align: center; margin: 25px" v-html="$t(`${type}_name_text`)"></h4>
        <p style="text-align: center" v-html="$t(`${type}_guide_text`)"></p>
        <div style="text-align: center; margin: 10px 0">
          <div v-if="isHard && !auth.id">
            <p>К сожалению ваш результат не будет сохранен.</p>
            <p>Зарегистрируйтесь на сайте.</p>
          </div>
          <div>
            <div style="text-align: center; margin: 10px 0">
              <button
                class="btn btn-primary"
                :class="{ disabled: prepare || !loadedList }"
                @click="
                  type = 'exam';
                  typeP.push(1);
                  onStart();
                "
              >
                <span v-if="!prepare || prepare != 'exam'"> Категория AB</span>
                <span v-if="prepare == 'exam' && !loadedB">Подготовка....{{ loadProgress }}%</span>
              </button>
            </div>
            <div style="text-align: center; margin: 10px 0">
              <button
                v-if="lang !== 'kg' && false"
                class="btn btn-primary"
                :class="{ disabled: prepare || !loadedList }"
                @click="
                  type = 'exam';
                  typeP.push(3);
                  onStart();
                "
              >
                <span v-if="!prepare || prepare != 'exam'"> Категория CD</span>
                <span v-if="prepare == 'exam' && !loadedB">Подготовка....{{ loadProgress }}%</span>
              </button>
            </div>

            <div style="margin-top: 10px">Включить дополнительные вопросы</div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" :value="2" id="typeJOLDO" v-model="typeP" />
              <label class="form-check-label" for="typeJOLDO"> Вопросы JOLDO </label>
            </div>
          </div>
          <div v-if="false" style="text-align: center; margin: 10px 0">
            <button
              class="btn btn-primary"
              :class="{ disabled: prepare || !loadedList }"
              @click="
                type = 'exam';
                typeP = [1];
                onStart();
              "
            >
              <span v-if="!prepare || prepare != 'exam'">Только вопросы ГРС</span>
              <span v-if="prepare == 'exam' && !loadedB">Подготовка....{{ loadProgress }}%</span>
            </button>
          </div>
          <div v-if="false" style="text-align: center; margin: 10px 0">
            <button
              class="btn btn-primary"
              :class="{ disabled: prepare || !loadedList }"
              @click="
                type = 'rush';
                typeP = [1, 2];
                onStart();
              "
            >
              <span v-if="!prepare || prepare != 'rush'">Вопросы ГРС+JOLDO</span>
              <span v-if="prepare == 'rush' && !loadedB">Подготовка....{{ loadProgress }}%</span>
            </button>
          </div>
          <div v-if="isHard && $root.v2" style="text-align: center; margin: 30px 0 0 0">
            <p style="text-align: center">Ваш текущий ранг:</p>
            <img :src="rangImage" class="img-fluid" alt="Ваш ранг" style="height: 150px" />
          </div>
        </div>
      </div>
    </div>

    <div v-if="start || isAnalys">
      <div v-if="end.show && !isAnalys" style="max-width: 520px; margin: auto">
        <Result />
        <div style="display: flex; justify-content: center; gap: 30px; margin-top: 20px">
          <!-- Кнопка Еще раз -->
          <div style="text-align: center">
            <button style="background: none; border: none; cursor: pointer" @click="onStart(biletId)">
              <img src="https://img.icons8.com/color/48/restart.png" alt="Еще раз" style="width: 48px" />
            </button>
            <p style="margin-top: 5px; font-size: 12px">Еще раз</p>
          </div>

          <!-- Кнопка Результат -->
          <div style="text-align: center">
            <button style="background: none; border: none; cursor: pointer" @click="isAnalys = true">
              <img src="https://img.icons8.com/color/48/statistics.png" alt="Результат" style="width: 48px" />
            </button>
            <p style="margin-top: 5px; font-size: 12px">{{ type == "marathon" ? "Посмотреть ошибки" : "Проверка результатов" }}</p>
          </div>

          <!-- Кнопка Рейтинг -->
          <div style="text-align: center">
            <a href="https://joldo.kg/ru/rating.html" style="text-decoration: none; color: inherit"
              ><button style="background: none; border: none; cursor: pointer">
                <img src="https://img.icons8.com/color/48/trophy.png" alt="Рейтинг" style="width: 48px" />
              </button>
              <p style="margin-top: 5px; font-size: 12px">Рейтинг</p>
            </a>
          </div>
        </div>
      </div>
      <div v-if="bilet && !(end.show && !isAnalys)" ref="test_block">
        <div v-if="isAdmin && !true">
          isAnalys:{{ isAnalys }}
          <span> bilet: {{ bilet ? "true" : "false" }} </span>
        </div>
        <show-bilet-training
          :bilet="bilet"
          :canHelp="isAnalys || canHelp"
          :isHelp="showHelp"
          :isHard="isHard"
          :isAnalys="isAnalys"
          :results="results"
          :qId="qId"
          :lang="lang"
          @onAnswer="onNext($event)"
          @onUseHelp="useHelp($event)"
          @endAnalis="isAnalys = !true"
          class="px-2"
        >
          <template v-slot:title>
            <div v-if="!isAnalys && timer" style="font-size: 26px">{{ time }}</div>
            <div v-else style="font-size: 26px"></div>

            <div v-if="$root.authData && $root.authData.id">
              <i v-if="favorites.includes(bilet.id)" class="fas fa-star" @click="onStarClick(bilet.id, 0)"></i>
              <i v-else class="far fa-star" @click="onStarClick(bilet.id, 1)"></i>
            </div>
            <div v-else>
              <i v-if="!adFavorite" class="fas fa-star" @click.stop="showAdFavorite()"></i>
              <span v-else>Авторизуйтесь на сайте</span>
            </div>
          </template>
        </show-bilet-training>
        <div v-if="type == 'marathon' || maxBQ > 1250" style="text-align: center">
          <div v-if="isAnalys">
            <div style="display: inline-block; margin: 1px" v-for="(el, key) in results" :key="key" v-if="el.result == 2">
              <div
                style="width: 30px; height: 30px; border-radius: 50%; padding: 0px; color: #fff; cursor: pointer"
                :style="{ backgroundColor: getbackgroundColor(key), border: qId == el.num ? '2px solid #a33' : '2px solid #888' }"
                @click="onClickQ(el.num)"
              >
                {{ el.num }}
              </div>
            </div>
          </div>
          <div v-else>
            <div style="display: inline-block; margin: 1px" v-for="r of [0, 1, 2]" :key="`m-${r}`">
              <div class="px-2" style="border-radius: 15px; color: #fff" :style="{ backgroundColor: getbackgroundColor(r) }">
                <div class="mx-2">{{ countResults(r) }}</div>
              </div>
            </div>
            <div style="display: inline-block; margin: 1px">
              <div class="px-2" style="border-radius: 15px; color: #fff; cursor: pointer" :style="{ backgroundColor: getbackgroundColor(0) }" @click="gotoNext()">
                <div class="mx-2">Пропустить</div>
              </div>
            </div>
            <div v-if="true || ($root.authData && [36, 40].includes($root.authData.id))" style="display: inline-block; margin: 1px">
              <div class="px-2" style="border-radius: 15px; color: #fff; cursor: pointer" :style="{ backgroundColor: getbackgroundColor(0) }" @click="onEnd()">
                <div class="mx-2">Завершить</div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else-if="width < $root.widthMobil"
          style="text-align: center; overflow-x: scroll; white-space: nowrap; min-height: 40px"
          class="px-1"
          ref="scrollContainer"
        >
          <div style="display: inline-block; margin: 1px" v-for="(el, key) in results" :key="key" :ref="'result' + el.num">
            <div
              style="width: 30px; height: 30px; border-radius: 50%; padding: 0px; color: #fff; cursor: pointer"
              :style="{ backgroundColor: getbackgroundColor(key), border: qId == el.num ? '2px solid #a33' : '2px solid #888' }"
              @click="onClickQ(el.num)"
            >
              {{ el.num }}
            </div>
          </div>
        </div>
        <div v-else style="text-align: center" class="px-1">
          <div style="display: inline-block; margin: 1px" v-for="(el, key) in results" :key="key">
            <div
              style="width: 30px; height: 30px; border-radius: 50%; padding: 0px; color: #fff; cursor: pointer"
              :style="{ backgroundColor: getbackgroundColor(key), border: qId == el.num ? '2px solid #a33' : '2px solid #888' }"
              @click="onClickQ(el.num)"
            >
              {{ el.num }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal v-if="showModal" @close="onModalClose($event)" />
    <div class="modal fade" id="infoModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Обратите внимание</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">Вопросы для тестирования на категорию CD отличаются от тестов ГРС</div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="closeModal">Закрыть</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const secret = "DSFw#RSDGh54#$RDF";
const crypto = require("crypto");

import { data, reward, testing, userInfo } from "@/components/mixings";

export default {
  mixins: [data, reward, testing, userInfo],
  components: {
    Topic: () => import("./testTopic"),
  },
  data() {
    return {
      showModal: false,
      uu: [],
      typeSelected: false,
      typeP: [],
      isHard: this.$route.meta.isHard,
      type: this.$route.meta.type || "test",
      isAnalys: false,
      rating: 0,
      ratingBest: 0,
      maxtime: 25,
      time: null,
      timeUse: 0,
      timer: null,
      canRefreshAD: true,
      start: false,
      prepare: false,
      maxQ: 20,
      maxBQ: 0,
      maxBilet: 35,
      biletId: 1,
      qId: 1,

      bilets: {},
      q_list: [],
      q_full: {},
      groups: {},
      subGroups: [],
      subG: {},
      adFavorite: false,
      favorites: [],
      errors: [],
      myErrors: 0,
      showHelp: false,
      showRewards: false,
      loadedList: false,
      loadedG: false,
      loadedB: false,
      loadData: 0,
      lang: this.$route.meta.lang,
      authData: this.$root.authData,
      results: {},
      biletNumbers: [],
      errors: 0,
      end: {
        timer: false,
        show: false,
      },
      selected: 0,
      isTestVer: true,
      loadProgress: 0,
    };
  },
  created() {
    this.$root.lang = this.lang;
    this.resetTimer();
    this.fitchList();
    //if (this.type == "topic") this.fitchG();
    if (this.type == "myErrors") this.fitchErr();
    this.$root.loaded = true;
    for (var i = 1; i <= this.maxBilet; i++) {
      this.biletNumbers.push(i);
    }
    this.getRating();
  },
  mounted() {},

  computed: {
    width() {
      return this.$root.width;
    },
    fullname() {
      let name = "";
      if (this.auth.id) {
        name = this.auth.first_name || this.auth.last_name ? this.auth.first_name + " " + this.auth.last_name : this.auth.username;
      }
      return name;
    },

    auth() {
      const res = this.$root.authData || {};
      //console.log("res auth", res);
      // console.log("type res auth", typeof res);
      return res;
    },
    bilet() {
      //console.log("get current question");
      if (!this.qId) return false;
      let b = 0; //this.biletId;
      //if (this.auth.id == 36 || this.isTestVer)
      let res = this.bilets[b][this.qId] || false;
      //console.log(this.bilets, b, this.qId, res);
      return res;
    },
    listBilet() {
      let res = [];
      if (this.auth.id == 36 || this.isTestVer) {
        for (const row of this.q_list) {
          //if (row.num_bilet < 100 && !res.includes(row.num_bilet)) res.push(row.num_bilet);
          if (row.type == 1 && !res.includes(row.num_bilet)) res.push(row.num_bilet);
        }
      } else {
        for (const row of this.q_list) {
          if (row.type == 1 && !res.includes(row.num_bilet)) res.push(row.num_bilet);
        }
      }
      if (this.auth.id == 36) console.log("listBil", res, this.bilets);
      return res;
    },
    listBiletCD() {
      let res = [];
      for (const row of this.q_list) {
        if (row.type == 3 && !res.includes(row.num_bilet)) res.push(row.num_bilet);
      }
      if (this.auth.id == 36) console.log("listBiletCD", res, this.bilets);
      return res;
    },
    listBiletExt() {
      let res = [];
      if (this.auth.id == 36 || this.isTestVer) {
      }
      for (const row of this.q_list) {
        if (row.type == 2 && !res.includes(row.num_bilet)) res.push(row.num_bilet);
      }
      return res;
    },

    canHelp() {
      return !(this.isHard || this.type == "marathon");
    },
  },
  watch: {
    prepare(v) {
      if (v) this.loadProgress = 0;
    },
    qId(v) {
      this.$emit("changeAD");
      this.scrollToItem(v);
      const el = this.$refs.ad_block;
      const test = this.$refs.test_block;
      if (test) {
        try {
          //if (this.auth.id == 36) console.log("block", test);
          if (this.width >= this.$root.widthMobil || true) test.scrollIntoView();
        } catch (error) {
          console.log("scroll error", error);
        }
      } else {
        //console.log("no block");
      }
      this.$root.title = this.$root.title1 + " | Вопрос - " + v + (this.bilet ? " | " + this.bilet?.ques : "");
      //console.log(this.bilet);
      //  window.parent.postMessage('test', '*')
    },
  },
  methods: {
    scrollToItem1(num) {
      // Прокручиваем к выбранному элементу
      this.$nextTick(() => {
        const selectedItem = this.$refs["result" + num][0];
        selectedItem.scrollIntoView({ behavior: "smooth", inline: "center" });
      });
    },
    scrollToItem(num) {
      this.$nextTick(() => {
        const container = this.$refs.scrollContainer;
        if (!container) return;
        const selectedItem = this.$refs["result" + num][0];

        // Позиционируем выбранный элемент в центре контейнера
        const containerRect = container.getBoundingClientRect();
        const itemRect = selectedItem.getBoundingClientRect();
        const offset = itemRect.left - containerRect.left + container.scrollLeft;

        container.scrollTo({
          left: offset - containerRect.width / 2 + itemRect.width / 2,
          behavior: "smooth",
        });
      });
    },
    async onClickCD(el) {
      if ((this.$root.authData && [36, 40].includes(this.$root.authData.id)) || true) {
        this.showModal = true;
        //this.showModal();
        const result = await this.waitForModalClose();
        if (result !== "confirm") return;
      }
      this.typeP = [3];
      this.onStart(el);
    },
    waitForModalClose() {
      return new Promise(resolve => {
        this.$once("modal-closed", e => {
          resolve(e);
        });
      });
    },
    onModalClose(e) {
      this.showModal = false;
      this.$emit("modal-closed", e);
    },

    onClearError() {
      this.fitchErr("clear");
    },
    showAdFavorite() {
      this.adFavorite = true;
      setTimeout(() => {
        this.adFavorite = false;
      }, 2000);
    },
    getbackgroundColor(key) {
      let result; //= this.results?.[key]?.result || 0;
      if ((this.type == "marathon" || this.maxBQ > 1250) && !this.isAnalys) result = key;
      else result = this.results[key].result;
      if (result == 0) {
        return "#aaa";
      } else if (result == 2) {
        return "red";
      } else if (result == 1) {
        return "green";
      }
    },
    /*showModal() {
      $("#infoModal").modal("show");
    },
   */ closeModal() {
      $("#infoModal").modal("hide");
    },
    refreshAD__() {
      this.$root.width = window.innerWidth;
      this.canRefreshAD = false;
      this.$root.showAD = true;
      console.log("refresh AD");
      if (!true)
        setTimeout(() => {
          (adsbygoogle = window.adsbygoogle || []).push({});
          setTimeout(() => {
            (adsbygoogle = window.adsbygoogle || []).push({});
          }, 500);
        }, 500);
      setTimeout(() => {
        this.canRefreshAD = true;
      }, 7000);
    },
    resetTimer() {
      this.time = this.maxtime + ":00";
    },

    getTimeStr(timer) {
      let minutes = parseInt(timer / 60, 10);
      let seconds = parseInt(timer % 60, 10);
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      return minutes + ":" + seconds;
    },

    countResults: function (st) {
      let r = 0;
      for (let i = 1; i <= this.maxBQ; i++) {
        if (this.results[i].result == st) {
          r = r + 1;
        }
      }
      return r;
    },
    onClickQ(num) {
      this.showHelp = false;
      this.qId = num;
    },
    gotoNext() {
      for (let i = this.qId; i <= this.maxBQ; i++) {
        if (this.results[i].result == 0 && i !== this.qId) {
          this.qId = i;
          return;
        }
      }
      for (let i = 1; i < this.qId; i++) {
        if (this.results[i].result == 0) {
          this.qId = i;
          return;
        }
      }
      this.onEnd();
    },
    useHelp(key) {
      let c = this.bilets[0][key]?.useHelp || 0;
      c++;
      this.bilets[0][key]["useHelp"] = c;
    },
    onNext(key) {
      //событие ответа на вопрос
      if (this.isAnalys) return;
      let stat = 0;
      this.showHelp = false;
      let res = Object.assign({}, this.results[this.qId], {
        id: this.bilet?.id,
        num_b: this.bilet.num_bilet,
        num_q: this.bilet.num_q,
        num_a: key,
        type: this.bilet.type,
        useHelp: this.bilet.useHelp,
      });
      if (this.results?.[this.qId]?.result === 0) this.results[this.qId] = res;
      if (this.bilet.answers[key].istrue == 1) {
        if (this.results[this.qId].result === 0) {
          stat = this.qId;
          this.results[this.qId].result = 1;
          this.results[this.qId].point = this.bilet.type == 2 ? 3 : 1; //если доп вопрос - тогда 3 балла
          this.setStatistic(this.results[stat]);
        }
        this.gotoNext();
      } else {
        if (this.results[this.qId].result === 0) {
          stat = this.qId;
          this.results[this.qId].result = 2;
          this.errors = this.errors + 1;
          this.results[this.qId].point = -1;
          this.setStatistic(this.results[stat]);
        }
        if (!this.canHelp) this.gotoNext();
        else this.showHelp = true;
      }
    },
    onStop() {
      this.showRewards = false;
      this.isAnalys = false;
      this.rating = 0;
      this.isHard = false;
      this.timeUse = 0;
      this.results = {};
      for (var i = 1; i <= this.maxBQ; i++) {
        this.results[i] = {
          result: 0,
          time: 0,
          num: i,
        };
      }
      clearInterval(this.timer);
      this.resetTimer();
      this.errors = 0;
      this.start = false;
      this.end = {
        timer: false,
        show: false,
      };
    },
    async onEnd(t = false) {
      //событие окончание теста
      if (this.type == "myErrors") {
        this.onStop();
        this.fitchErr();
        return;
      }
      clearInterval(this.timer);
      this.end = { timer: t, show: true };
      if (this.isHard) await this.setStatExam();
      else if (this.type == "marathon") await this.setStatExam();
      this.makeUserInfo();
    },

    async onStarClick(id, type) {
      let f = this.favorites;
      if (id) {
        if (await this.postData2(this.$root.config.baseUrl + "/addFavorites", { id, type })) {
          if (type === 1) f = [...f, id];
          if (type === 0) f = [...f.filter(el => el !== id)];
        }
      }
      this.favorites = [...f];
      // console.log("favorites", this.favorites);
    },
    async fitchErr(t = "") {
      this.myErrors = 0;
      try {
        let type = t ? `?type=${t}` : "";
        await fetch(this.$root.config.baseUrl + "/getMyErrorsPdd" + type, {
          method: "GET",
          credentials: "include",
          headers: {
            "Access-Control-Allow-Origin": this.$root.config.baseUrl,
          },
        })
          .then(response => {
            if (!response.ok) {
              throw new Error("Ошибка при выполнении запроса");
            }
            return response.json();
          })
          .then(res => {
            let list = res.map(f => {
              return f.id;
            });
            this.errors = [...list];
            this.loadedB = true;
            this.myErrors = list.length;
            //console.log("ressss errors ", res, this.errors, this.myErrors);
          })
          .catch(error => {
            console.error(error);
          });
      } catch (error) {}

      return this.loadedB;
    },

    async fitchList() {
      this.favorites = [];
      try {
        await fetch(this.$root.config.baseUrl + "/getFavorites", {
          method: "GET",
          credentials: "include",
          headers: {
            "Access-Control-Allow-Origin": this.$root.config.baseUrl,
          },
        })
          .then(response => {
            if (!response.ok) {
              throw new Error("Ошибка при выполнении запроса");
            }
            return response.json();
          })
          .then(res => {
            let list = res.map(f => {
              return f.pdd_id;
            });
            this.favorites = [...list];
          })
          .catch(error => {
            console.log(error);
          });
      } catch (error) {}

      fetch(this.$root.config.baseUrl + `/list2/get/${this.lang}?type=` + this.type, {
        method: "GET",
      })
        .then(response => {
          if (!response.ok) throw new Error("Ошибка при выполнении запроса 2");
          return response.json();
        })
        .then(res => {
          //const decipher = crypto.createDecipher("aes-256-cbc", secret);
          // let data = decipher.update(res, "hex", "utf-8");

          // data += decipher.final("utf-8");
          let data = res;
          this.groups = data.g;
          for (let r of this.groups) {
            for (let q in r) {
              try {
                r[q] = JSON.parse(r[q]);
              } catch (err) {
                r[q] = r[q];
              }
            }
          }
          this.q_list = data.q;
          //console.log(data);
          this.loadedG = true;
          this.loadedList = true;
        })
        .catch(error => {
          console.error(error);
        });
      return this.loadedList;
    },

    setStatistic(s) {
      let data = Object.assign({}, s);
      data["type"] = this.type;
      data["regdate"] = new Date().datetime;
      if (s.result == 2 && this.isHard) this.spaceRewards(this.results);
      if (s.time) {
        this.postData(this.$root.config.baseUrl + "/statisticUser", data);
      } else {
        this.postData(this.$root.config.baseUrl + "/statisticUser", data);
      }
    },

    async setStatExam() {
      if (this.type == "marathon") {
        await this.makeMarathon(this.results, this.timeUse);
        return;
      }
      try {
        await this.makeRewards(this.results, this.type);
        this.showRewards = true;
      } catch (error) {
        console.log("error", error);
      }
      let time = 0;
      let value = 0;
      let point = 0;
      let correct = this.countResults(1);
      for (let i = 1; i <= this.maxBQ; i++) {
        time += this.results[i].time;
        point += this.results[i].point;
        // value += this.assignScore(this.results[i].time, this.results[i].result == 1);
      }
      //console.log("result", point, this.results);
      value = correct * this.maxtime * 60 - time;
      let s = {
        time,
        correct,
        value,
      };
      if (!time) return;
      //this.getStatExam(s.value);
      if (!this.auth.id) return;
      if (this.countResults(0) > 2) return;
      const formDataString = Object.keys(s)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(s[key]))
        .join("&");
      if (this.auth.id && ![-36].includes(this.auth.id)) this.postData(this.$root.config.baseUrl + "/statisticExam", formDataString);
    },

    getStatExam(v) {
      fetch(this.$root.config.baseUrl + "/statisticExam/" + v, {
        method: "GET",
        credentials: "include",
      })
        .then(response => {
          if (!response.ok) {
            throw new Error("Ошибка при выполнении запроса");
          }
          return response.json();
        })
        .then(data => {
          this.rating = data[0];
          this.ratingBest = data[1];
        })
        .catch(error => {
          console.error(error);
        });
    },
  },
};
</script>
